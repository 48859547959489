#find-a-location-root {
  max-width: 1440px;
}

#parent-container {
  display: flex;
}

#parent-container.widget-presentation {
  flex-flow: column nowrap;
  flex-direction: column-reverse;
}


#parent-container.traditional-presentation .left-container {
  width: calc(60% - 40px);
  padding: 0px 20px;
}

.aem-Grid #parent-container.traditional-presentation {
  flex-flow: row nowrap;
  justify-content: center;
}

.aem-Grid #parent-container.traditional-presentation .left-container {
  width: calc(60% - 20px);
  padding-left: 0px;
}

#parent-container.traditional-presentation .right-container {
  width: 40%;
}

.aem-Grid #parent-container.traditional-presentation .right-container {
  width: calc(30vw);
  padding-left: 40px;
  position: relative;
}

.aem-Grid #parent-container.traditional-presentation .right-container .map-container {
  position: fixed;
  width: calc(30vw);
  max-width: 528px;
  margin: unset;
}

.aem-Grid #parent-container.traditional-presentation .right-container .map-container.at-bottom {
  position: absolute;
  width: calc(30vw);
  bottom: 60px;
}

#parent-container.widget-presentation .left-container {
  width: 100%;
  padding: 0px;
}

#parent-container.widget-presentation .right-container {
  height: 300px;
}

input:disabled+label {
  color: #d9d8d6;
  cursor: text;
}

hr {
  margin-bottom: 0;
}

a {
  color: #007785;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.primary {
  color: #007785;
}

.paragraph-normal {
  font-size: 16px;
  letter-spacing: .1px;
  line-height: 22.0833px;
}

.no-margin {
  margin: 0px;
}

a:hover {
  text-decoration: underline;
}

.result-item-title {
  font-size: 16px;
  font-weight: 700;
}

.result-item-address {
  font-size: 14px;
  font-weight: 500;
}

.result-item-address span {
  display: block;
}

.location-search-label {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.1px;
  padding-bottom: 10px;
}

.searchWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;
}

.searchInnerWrapper {
  width: 40%;
  min-width: 250px;
  max-width: 400px;
}

.searchInnerWrapper button {
  position: absolute;
  right: 3px;
  top: 2px;
  width: 30px;
}

.checkbox-container {
  display: flex;
  padding: 0px;
  margin: 0px;
}

.checkbox-container input[type="checkbox"] {
	visibility: hidden;
  position: absolute;
  top: .5px;
}

.checkbox-container input[type="checkbox"] + label:focus {
  outline: solid 2px #0097a9 !important;
}

.checkbox-container input + label {
	display: inline-block;	
	/* cursor: pointer; */
	background-image: url('./images/unchecked-box.png');
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
  background-position: 100% 100%;
  background-size: cover;
}

.checkbox-container input[type="checkbox"]:checked + label {
	background-image: url('./images/checked-box.png')
}

.checkbox-container input[type="checkbox"]:disabled + label {
	background-image: url('./images/disabled-box.png') !important;
}

svg g.disabled:hover {
    fill: darkgray !important;
}

.location-search-input {
  border: 1px solid #87888A;
  box-sizing: border-box;
  border-radius: 3px;
  height: 44px;
  min-width: 250px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.input-container {
  display: flex;
  position: relative;
}

.input-container > input[type=text] {
  padding: 5px 5px 5px 33px;
}

.input-container > svg {
  /* height: 80px; */
  position: absolute;
  top: 14px;
  left: 10px;
}

.location-search-go {
  margin-left: 15px;
  padding: 0 20px;
  background: #007785;
  border: 2px solid #007785;
  box-sizing: border-box;
  border-radius: 55px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
}

.location-search-go:hover {
  background: #00606B;
  border: 2px solid #00606B;
}


.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
  border-bottom: 1px solid #76777a;
}

.accordion-title>h2 {
  margin-bottom: 10px;
}

#chevron {
  display:inline-block;
  width:50px;
  height:50px;
  background:
    linear-gradient(#007785,#007785),
    linear-gradient(#007785,#007785);
  background-position:center;
  background-size: 50% 2px,2px 50%; /*thickness = 1px, length = 50% (25px)*/
  background-repeat:no-repeat;
}

#chevron.active {
  display:inline-block;
  width:50px;
  height:50px;
  background:linear-gradient(#007785,#007785);
  background-position:center;
  background-size: 50% 2px,2px 50%; /*thickness = 1px, length = 50% (25px)*/
  background-repeat:no-repeat;}

.accordion-item.collapsed {
  display: none;
}

.accordion-item.expanded {
  display: block;
}

.accordion-item {
  width: 50%;
  margin-bottom: 10px;
  padding-left: 2px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
}

.accordion-item .checkbox-label {
  margin: 0px 7.5px;
  text-transform: capitalize;
  cursor: pointer;
}

.accordion-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  cursor: pointer;
}

.accordion-item input {
  width: 1em;
  height: 1em;
}

.accordion-item input {
  background-color: #00606B;
}

.accordion-item label {
  margin: 0px;
}

.acc-close {
  display: none;
}

h2.traditional-header{
  margin-top: 75px;
  margin-bottom: 30px;
}

h2.widget-header {
  margin: 35px 0px;
}

.result-item-parent-container {
  padding: 30px 0px;
  border-top: 0.5px solid #76777A;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.result-item-parent-container:first-of-type {
  border-top: 0px;
  padding-top: 0px;
}

.result-item-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  min-height: 175px;
  box-sizing: border-box;
}

.result-item-left-column {
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  justify-content: center;
}

.result-item-right-column {
  width: 100%;
  padding-left: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

.result-item-footer {
  padding-top: 5px;
}

.results-list-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  list-style: none !important;
  padding: 0;
  margin: 0 20px 10px;
  background: #fff;
  height: 48px;
  border-right: none;
}

.results-list-pagination li a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #007785;
  margin: 10px;
  padding: 1px 6px;
}

.results-list-pagination a {
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  border-top: none;
}

.previous a{
  display:inline-block;
  width:48px;
  height:48px;
  border: 2px solid #007785;
  border-radius: 50%;
  cursor: pointer;
}

.previous a:after {
  content: '';
	display: inline-block;
  margin: 12px 0 0 10px;
  width: 15px;
  height: 15px;
  border-top: 2px solid #007785;
  border-right: 2px solid #007785;
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.next a{
  display:inline-block;
  width:48px;
  height:48px;
  border: 2px solid #007785;
  border-radius: 50%;
  cursor: pointer;
}

.next a:after {
  content: '';
	display: inline-block;
  margin: 12px 0 0 6px;
  width: 15px;
  height: 15px;
  border-top: 2px solid #007785;
  border-right: 2px solid #007785;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabled a {
  border-color:#d9d8d6;
}

.disabled a::after {
  border-color:#d9d8d6;
}

.results-list-active-page a {
  color: #00606b !important;
  font-weight: bold;
  background-color: #f5f5f5;
  border-radius: 50%;
}

.break-me+li {
  display: none;
}

.result-item-image-wrapper {
  width: 250px
}

.result-item-image-wrapper img {
  width: 250px;
  height: 170px;
  object-fit: cover;
  margin: 0px;
}

.findalocation .aem-Grid .content-right-rail-container.fullBleedOverride .row {
  max-width: 100%;
}

body > div.pac-container.pac-logo.hdpi > div > span.pac-icon.pac-icon-search {
  display: none;
}

body > div.pac-container.pac-logo.hdpi > div > span.pac-icon.pac-icon-marker {
  display: none;
}

span.pac-matched {
  font-style: italic;
}

.pac-container {
  box-sizing: content-box;
  border: 2px solid #0097a9;
  border-top: none;
  border-radius: none;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pac-container:after {
  content: unset;
}

.pac-target-input:focus {
  outline: none !important;
  border: 2px solid #0097a9 !important;
  /* border-bottom: 1px solid #0097a9 !important; */
  border-radius: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.pac-item {
  border: unset;
  font-family: myriad-pro, "Myriad Pro", arial, Helvetica, sans-serif;
  padding: 4px 10px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #b1b1b1;
}

.pac-item:hover {
  background: #e8f6f7
}

.pac-item-query, .pac-item-query > span {
  color: #b1b1b1;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

/* input[type=search]:focus, input[type=text]:focus, select:focus, textarea:focus {
  border: unset;
} */

/* div a:focus, div input:focus, div button:focus, div .btn:focus {
  outline: solid 2px #0097a9 !important;
} */

@media (max-width: 900px) {

  .traditional-presentation .result-item-image-wrapper,
  .traditional-presentation .result-item-image-wrapper img {
    width: 175px;
    height: auto;
  }
}

@media (max-width: 768px) {
  #parent-container.traditional-presentation .left-container {
    width: 100% !important;
    padding: 0px !important;
  }

  #parent-container.traditional-presentation .right-container {
    display: none;
  }
}

@media (max-width: 450px) {
  .result-item-container {
    flex-flow: column nowrap;
  }

  .result-item-image-wrapper,
  .result-item-image-wrapper img {
    width: 100% !important;
    height: auto;
  }

  .result-item-right-column {
    padding-left: 0px;
    margin-top: 15px;
  }

  .result-item-right-column>* {
    margin-bottom: 10px;
  }

  .traditional-presentation .result-item-parent-container:first-of-type {
    padding-top: 30px;
  }
}